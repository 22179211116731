:root {
    --new-blue: #58708E;
    --light-blue: #BCD8FF;
    --num-gen-bg: #F5F5F5;
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
    --dark-blue: #151A21;
}



/*Right Col*/



.rowWrapperContacto {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 1.11vw, 16px); /*??*/
    width: clamp(1px, 44.3vw, 638px);
    color: var(--dark-blue);
    height: 124.96px;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
    border-bottom: 1px solid var(--dark-blue);
    cursor: pointer;
}



.rowWrapperContacto.clicked {
    height: 300px;
}

.rowTitleWrapperContacto {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    transition: all ease 0.5s;
}

.rowTitleWrapperContacto:hover {
    opacity: 0.5;
}





.rowTitleContainerContacto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: clamp(1px, 0.625, 9px);
    height: 125px;
    /*align-self: flex-end;*/
    
}

.accordeonButtonContacto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 3.54vw, 51px);
    height: clamp(1px, 3.54vw, 51px);

}



.rowTitleContacto {
    font-size: clamp(1px, 1.66vw, 24px);

}

.rowTextContainerContacto {
    padding-left: 1vw;
    width: clamp(1px, 31.11vw, 448px);
    text-wrap: wrap;
    font-size: clamp(1px, 1.66vw, 24px);
}


.accordeonContainerContacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 3.05vw, 44px);
    margin-top: clamp(1px, 12vw, 173px);

}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.accordeonIconContacto {
    width: max(0.76vw, 11px);
    height: max(1.18vw, 17px);
}


@media screen and (max-width: 478px) {

    
    .rowWrapperContacto {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 1.11vw, 16px); /*??*/
        /*width: max(83.81vw, 347px);*/
        width: 83vw;
        color: var(--dark-blue);
        height: 100px;
        overflow: hidden;
        transition: all ease-in-out 0.4s;
        border-bottom: 1px solid var(--dark-blue);
        cursor: pointer;
    }



    .rowWrapperContacto.clicked {
        height: 300px;
    }

    .rowTitleWrapperContacto {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        transition: all ease 0.5s;
    }

    .rowTitleWrapperContacto:hover {
        opacity: 1;
    }





    .rowTitleContainerContacto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: clamp(1px, 0.625, 9px);
        height: 100px;
        /*align-self: flex-end;*/
        
    }

    .accordeonButtonContacto {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(1px, 3.54vw, 51px);
        height: clamp(1px, 3.54vw, 51px);

    }



    .rowTitleContacto {
        font-size: max(3.38vw, 14px);

    }

    .rowTextContainerContacto {
        padding-left: 1vw;
        width: max(78.74vw, 326px);
        text-wrap: wrap;
        font-size: max(2.89vw, 12px);
        line-height: max(4.34vw, 18px);
    }


    .accordeonContainerContacto {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: clamp(1px, 3.05vw, 44px);
        margin-top: max(16.66vw, 69px);
        padding-bottom: max(19vw, 79px);

    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
}