/*margins = 1920-(1440-figma_distance) SAME AS just using 1440 vw values
en tamaños de objetos esta bien usar clamp, pero en margenes y distancias
conviene usar el valor vw sacado del vw del file, va a respetar las proporciones
bien.
sino estas limitando el max margin de un elemento al del que esta en el figma.
va a quedar todo estirado.*/


:root {
    --gris-oscuro: #8a8a8a;
    --new-blue: #58708e;
}

.hideDesktop {
    display: none;
}
 
.blueBubble1 {
    position: absolute;
    /*top: max(10.76vw, 155px);*/
    top: min(10.76vw, 155px);
    right: min(10.2vw, 147px);
    width: max(16.8vw, 242px);
    height: max(16.8vw, 242px);
    transition: all ease 1s;

}

.blueBubble2 {
    position: absolute;
    top: min(49.09vw, 707px);
    /*left: min(36.38vw, 524px);*/
    /*right: min(54.47vw, 1045px);*/
    right: 54.47vw;
    width: min(15.13vw, 218px);
    height: min(15.13vw, 218px);
    transition: all ease 1s;
}

.blueBubble3 {
    position: absolute;
    top: min(45.43vw, 899px);
    right: 33.79vw;
    width: max(9.65vw, 139px);
    height: max(9.65vw, 139px);
    z-index: 3;
    
    border-radius: 100%;
    transition: all ease 1s;
}

.blueBubbleComponent3 {
    position: absolute;
    top: min(45.43vw, 899px);
    right: 33.79vw;
    width: max(9.65vw, 139px);
    height: auto;
    z-index: 3;
    background-color: black;
    transition: all ease 1s;
}
 

.skyContainer {
    position: relative;
    background-image: url('../assets/skybgog.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 774px;
    width: 100%;
    height: 53.75vw;
    max-height: 774px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    
}

.avatar {
    position: absolute;
    left: 0;
    margin-left: min(41.73vw, 751px);
    width: 29.4vw;
    max-width: 424px;
    height: 61.66vw;
    max-height: 888px;
    margin-top: -115px;
    z-index: 2;
    
}



.mainText {
    
    left: 0;
    align-self: center;
    /*margin-left: min(8.125vw, 117px);*/
    margin-left: 117px;
    font-size: min(3.75vw, 54px);
    line-height: min(3.6vw, 51.84px);
    color: white;
    font-weight: 300;
    width: min(39.15vw, 555px);
    
}

.crecemosWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.84vw, 113px);
    
}

.crecemosText {
    color: var(--gris-oscuro);
    text-align: center;
    font-weight: 500;
    font-size: min(4.65vw, 67px);
    line-height: min(7.07vw, 101.84px);
    width: min(72.2vw, 1040px);
}

.crecemosQuoteWrapper {
    margin-top: min(43.4vw, 625px);
    align-self: flex-start;
    /*margin-left: min(10.92vw, 637px);*/
    margin-left: 10.92vw;
}

.crecemosQuoteContainer {
    display: flex;
    flex-direction: column;
    font-size: min(3.47vw, 50px);
    line-height: min(4.68vw, 67.5px);
    color: var(--new-blue);
    gap: 18px;
    
    
}

/*Styling de img bubbles*/

.leaf {
   left: 0;
   /*margin-left: min(2.7vw, 39px);*/
   margin-left: 2.7vw;
   margin-top: -80px;
   width: min(20.83vw, 300px);
   height: auto;
}

.woman_dancing {
    left: 0;
    width: min(20.83vw, 300px);
    height: auto;
    margin-top: min(20.55vw, 296px);
    /*margin-left: min(6.8vw, 98px);*/
    margin-left: 6.8vw;
}

.waking_up {

    left: 0;
    margin-top: min(7.36vw, 106px);
    /*margin-left: min(44.02vw, 1286px);*/
    margin-left: 44.02vw;
    width: min(21.11vw, 304px);
    height: auto;
}

.almonds {
    left: 0;
    margin-top: min(7.36vw, 106px);
    /*margin-right: min(15.9vw, 229px);66.8*/
    margin-left: min(66.8vw, 1200px);
    width: min(17.22vw, 248px);
}

.salad {
    right: 0;
    margin-top: min(30.83vw, 444px);
    /*margin-right: min(35.55vw, 512px);*/
    margin-right: 33.55vw;
    width: min(14.09vw, 203px);

}

.woman_drinking {
    right: 0;
    margin-top: min(33.19vw, 478px);
    margin-right: clamp(202px, 14.02vw, 269px);
    width: min(20.83vw, 300px);
    height: auto;
    flex-shrink: 0;
}

.dna {
    right: 0;
    margin-top: min(54.6vw, 787px);
    margin-right: 7.36vw;
    /*margin-right: clamp(106px, 7.36vw, 268px);*/
    width: min(20.83vw, 300px);
    height: auto;
}

.cells {
    right: 0;
    margin-top: min(50.48vw, 727px);
    margin-right: 28.5vw;
    width: min(17.8vw, 257px);
    height: auto;
}

.fruits {
    left: 0;
    margin-top: min(73.9vw, 1065px);
    margin-left: 17.84vw;
    width: min(20.83vw, 300px);
    height: auto;
}

.cellphoneHomeWrapper {
    padding-top: 15.06vw;
}


/*-----------------------*/

.crecemosQuoteWrapperTwo {
    margin-top: min(9.58vw, 138px);
    align-self: flex-end;
    margin-right: 7.77vw;
    text-align: right;
}

.arrowDownWideWrapper {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
}


.mainTextMobileContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    
}
  

@media screen and (min-width: 479px) and (max-width: 1720px) {

    .blueBubble1 {
        position: absolute;
        /*top: max(10.76vw, 155px);*/
        top: min(10.76vw, 155px);
        right: min(10.2vw, 147px);
        width: max(16.8vw, 242px);
        height: max(16.8vw, 242px);
    }
    
    .blueBubble2 {
        position: absolute;
        top: min(49.09vw, 707px);
        /*left: min(36.38vw, 524px);*/
        /*right: min(54.47vw, 1045px);*/
        right: 50.47vw;
        width: min(15.13vw, 218px);
        height: min(15.13vw, 218px);
    }
    
    .blueBubble3 {
        position: absolute;
        top: min(50.43vw, 899px);
        right: 28.79vw;
        width: max(9.65vw, 139px);
        height: auto;
        z-index: 3;
    }
    .arrowDownWideWrapper {
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 9999;
        overflow: hidden;
    }

    .arrowPosContainer {
        margin-left: 13vw;
    }
}




@media screen  and (min-width: 479px) and (max-width: 1505px) {

    .avatar {
        /*margin-left: min(41.73vw, 751px);*/
        margin-left: 49vw;
        margin-top: -7.98vw;
    }


    .salad {
        margin-right: 28.55vw;
    }

    .woman_drinking {
        margin-right: clamp(2px, 8.02vw, 269px);
    }

    .cells {
        margin-right: 23.5vw;
    }

    .dna {
        margin-right: 1.36vw;
    }

    .blueBubble1 {
        position: absolute;
        /*top: max(10.76vw, 155px);*/
        top: min(10.76vw, 155px);
        right: min(10.2vw, 147px);
        width: max(16.8vw, 242px);
        height: max(16.8vw, 242px);
    }
    
    .blueBubble2 {
        position: absolute;
        top: min(49.09vw, 707px);
        /*left: min(36.38vw, 524px);*/
        /*right: min(54.47vw, 1045px);*/
        right: 41.47vw;
        width: min(15.13vw, 218px);
        height: min(15.13vw, 218px);
    }
    
    .blueBubble3 {
        position: absolute;
        top: min(58.43vw, 899px);
        right: 20.79vw;
        width: max(9.65vw, 139px);
        height: auto;
        z-index: 3;
    }

        
    .arrowDownWideWrapper {
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 9999;
        
    }

    .arrowPosContainer {
        margin-left: 14vw;
    }

    
}


/* MOBILE */

@media screen and (max-width: 478px) {


    .blueBubble1 {
        position: absolute;
        /*top: max(10.76vw, 155px);*/
        top: 0;
        z-index: 99;
        right: min(10.2vw, 147px);
        width: max(29.65vw, 122.76px);
        height: max(29.65vw, 122.76px);
        right: -10vw;
    }
    
    .blueBubble2 {
        position: absolute;
        top: initial;
        bottom: 5vw;
        /*left: min(36.38vw, 524px);*/
        /*right: min(54.47vw, 1045px);*/
        left: 19vw;
        width: max(26.57vw, 110px);
        height: max(26.57vw, 110px);
    }
    
    .blueBubble3 {
        position: absolute;
        top: initial;
        bottom: -9vw;
        right: 28.79vw;
        width: max(16vw, 70px);
        height: auto;
        z-index: 30;
        overflow: visible;
    }

    .skyContainer {
        position: relative;
        background-image: url('../assets/skymobile.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 450px;
        width: 100%;
        height: 101.81vw;
        max-height: 774px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        
    }
    
    .avatarMobileContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }


    .avatar {
        all: initial;
        width: 51.94vw;
        max-width: 215px;
        height: 108.81vw;
        max-height: 450.5px;
        z-index: 0;
        filter: brightness(0.9);
    }
    
    
    .mainText {
        
        all: initial;
        align-self: center;
        /*margin-left: min(8.125vw, 117px);*/
        font-size: min(5.79vw, 24px);
        line-height: min(7.53vw, 31.2px);
        color: white;
        font-weight: 300;
        z-index: 1;
        text-align: center;
        width: min(70.04vw, 290px);
        font-family: 'Mont';
        
    }

    .mainTextMobileContainer {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        
    }
    
    .crecemosWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: min(7.84vw, 113px);
        overflow: hidden;
    }
    
    .crecemosText {
        color: var(--gris-oscuro);
        text-align: center;
        font-weight: 300;
        font-size: min(7.24vw, 30px);
        line-height: min(9.66vw, 40px);
        width: min(83.57vw, 346px);
    }
    
    .crecemosQuoteWrapper {
        margin-top: min(102.4vw, 424px);
        align-self: center;
        /*margin-left: min(10.92vw, 637px);*/
        margin-left: 0;
        z-index: 1;
    }
    
    .crecemosQuoteContainer {
        display: flex;
        flex-direction: column;
        font-size: min(5.79vw, 24px);
        line-height: min(7.53vw, 31.2px);
        color: var(--new-blue);
        gap: 33px;
        text-align: center;
        align-items: center;
    }
    
    /*Styling de img bubbles*/
    
    .leaf {
       left: 7px;
       /*margin-left: min(2.7vw, 39px);*/
       margin-left: 2.7vw;
       margin-top: 10px;
       width: min(31.48vw, 130.36px);
       height: auto;
    }
    
    .woman_dancing {
        left: 0;
        width: min(31.48vw, 130.36px);
        height: auto;
        margin-top: 170px;
        /*margin-left: min(6.8vw, 98px);*/
        margin-left: 6.8vw;
    }
    
    .waking_up {
    
        left: 0;
        margin-top: min(15.36vw, 106px);
        /*margin-left: min(44.02vw, 1286px);*/
        margin-left: 43.7vw;
        width: min(26.32vw, 109px);
        height: auto;
    }
    
    .almonds {
        left: 0;
        margin-top: -25px;
        /*margin-right: min(15.9vw, 229px);66.8*/
        margin-left: min(85vw, 1200px);
        width: min(24.87vw, 103px);
    }
    
    .salad {
        right: 0;
        margin-top: 105px;
        /*margin-right: min(35.55vw, 512px);*/
        margin-right: 2.97vw;
        width: min(22.14vw, 91.66px);
    
    }
    
    .woman_drinking {
        right: 0;
        margin-top: 196px;
        margin-right: 138px;
        width: min(16.9vw, 70px);
        height: auto;
        flex-shrink: 0;
    }
    
    .dna {
        right: 0;
        margin-top: min(132.85vw, 550px);
        margin-right: -7vw;
        /*margin-right: clamp(106px, 7.36vw, 268px);*/
        width: min(21.98vw, 91px);
        height: auto;
    }
    
    .cells {
        /*right: 0;*/
        left: 0;
        margin-top: min(135.26vw, 560px);
        margin-left: -5vw;
        width: min(28vw, 116px);
        height: auto;
    }
    
    .fruits {
        
        right: 0;
        margin-top: min(200vw, 830px);
        margin-left: 75vw;
        width: min(20.83vw, 300px);
        height: auto;
    }
    
    .cellphoneHomeWrapper {
        padding-top: 27.53vw;
    }
    
    
    /*-----------------------*/
    
    .crecemosQuoteWrapperTwo {
        margin-top: 31px;
        align-self: center;
        text-align: center;
        margin-right: inherit;
    }
    
    .arrowDownWideWrapper {
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
    
    .hide {
        display: none;
    }

}
