:root {
    --new-blue: #58708E;
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
}

.recOuterWrapper {
    display: flex;
    flex-direction: row;
    gap: 0.55vw;
    transition: all ease-in-out 0.5s;
}


.recWrapper {
    display: flex;
    flex-direction: column;
    
}

.imgTitleContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    

}

.recommendedPfp {
    width: clamp(1px, 27.36vw, 394px);
    height: clamp(1px, 28.07vw, 404.3px);
    transition: var(--out-quint) 1s;

}

.recomendacionTitle {
    margin-top: clamp(1px, 1.07vw, 15.46px);
    font-size: clamp(1px, 2.29vw, 33px);
    line-height: 33px;
    color: var(--new-blue);
    letter-spacing: -2.47px;
    width: clamp(1px, 27.36vw, 394px);
    text-wrap: wrap;
    height: clamp(1px, 5vw, 90px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.recomendacionSubtitle {
    font-size: clamp(1px, 1.11vw, 16px);
    transition: all var(--out-quint) 1s;
    color: var(--new-blue);
    width: clamp(1px, 26.59vw, 383px);
    height: clamp(1px, 4.72vw, 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    
    
}

.rightLine {
    width: 1px;
    height: auto;
    background-color: #E0E0E0;
}

.hoverArrow {
    position: absolute;
    right: 30px;
    top: 380px;
    z-index: 9999;
    opacity: 0;
    transition: all var(--out-quint) 1s;
}


@media screen and (min-width: 479px) and (max-width: 1588px) {

    .recomendacionTitle {
        overflow: visible;
    }

}


@media screen and (max-width: 478px) {

    
    .recOuterWrapper {
        display: flex;
        flex-direction: row;
        gap: 0.55vw;
        transition: all ease-in-out 0.5s;
    }
    
    
    .recWrapper {
        display: flex;
        flex-direction: column;
        
    }
    
    .imgTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        
    
    }
    
    .recommendedPfp {
        width: clamp(1px, 38.06vw, 157.59px);
        height: clamp(1px, 39.05vw, 161.7px);
        transition: var(--out-quint) 1s;
    
    }
    
    .recomendacionTitle {
        margin-top: clamp(1px, 1.07vw, 15.46px);
        font-size: clamp(1px, 3.38vw, 14px);
        letter-spacing: -1px;
        width: clamp(1px, 37.9vw, 157px);
        height: clamp(1px, 9.25vw, 40px);
        color: var(--new-blue);
    }
    
    .recomendacionSubtitle {
        display: none;
        font-size: clamp(1px, 2.11vw, 16px);
        transition: all var(--out-quint) 1s;
        color: var(--new-blue);
        width: clamp(1px, 34.59vw, 383px);
        
    }
    
    .rightLine {
        width: 1px;
        height: auto;
        background-color: #E0E0E0;
    }
    
    .hoverArrow {
        display: none;
        position: absolute;
        right: 30px;
        top: 380px;
        z-index: 9999;
        opacity: 0;
        transition: all var(--out-quint) 1s;
    }
    
    
}

