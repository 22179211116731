:root {
    --new-blue: #58708E;
    --light-blue: #BCD8FF;
    --num-gen-bg: #F5F5F5;
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
    --dark-blue: #151A21;
}



/*Right Col*/



.rowWrapperPlanes {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 1.11vw, 16px); /*??*/
    /*width: max(83.81vw, 347px);*/
    width: 83.81vw;
    color: var(--dark-blue);
    height: 65.99px;
    overflow: hidden;
    transition: all ease-in-out 0.5s;
    border-bottom: 1px solid white;
    cursor: pointer;
    
}



.rowWrapperPlanes.clicked {
    height: 300px;
}

.rowTitleWrapperPlanes {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    transition: all ease 0.5s;
    

}




.rowTitleContainerPlanes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    height: 66px;
    /*align-self: flex-end;*/
    
}

.accordeonButtonPlanes {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--out-quint) 1s;
    
}



.rowTitlePlanes {
    font-size: max(5.31vw, 22px);
    color: white;
    font-family: 'Mont';
    letter-spacing: 8.8px;

}

.rowTextContainerPlanes {
    
    width: clamp(1px, 78.74vw, 326px);
    font-size: clamp(1px, 1.66vw, 24px);
}


.accordeonContainerPlanes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 3.05vw, 44px);
    margin-top: clamp(1px, 12vw, 173px);

}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.planList {
    font-size: max(4.83vw, 20px);
    line-height: max(6.61vw, 27.4px);
    letter-spacing: -0.6px;
    color: white;
}

.planesText {
    font-size: max(3.62vw, 15px);
    line-height: max(5.07vw, 21px);
    color: white;
    font-family: 'Mont';
    font-weight: 300;
}

.bottomRowLinksPlanes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: max(8.21vw, 34px);
    flex-wrap: wrap;
    
}

.downloadText {
    font-size: max(2.89vw, 12px);
    text-decoration: underline;
    color: white;
    font-family: 'Mont';
}

@media screen and (max-width: 478px) {

    ul {
        padding-left: 25px;
    }
    
}

@media screen and (max-width: 405px) {

    .bottomRowLinksPlanes {
        
        gap: 6.83vw;
    }
    
}