:root {
    --dark-blue: #151A21;
    --new-blue: #58708E;
}


.cellphoneOuterWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5vw;
}

.cellphoneInnerWrapper {
    display: flex;
    flex-direction: row;
    gap: clamp(1px, 4.23vw, 61px);
    align-items: center;
}


.titleText {
    font-size: clamp(1px, 1.66vw, 24px);
    line-height: clamp(1px, 2.51vw, 36.24px);
    text-align: center;
    width: clamp(1px, 34.51vw, 497px);
    color: #58708E;
}

.recsCol {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 4.51vw, 65px);
    position: relative;
    margin-right: 0px;
    margin-left: 0px;
    transition: all ease-in-out 1s;
    

}

.recRow {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    gap: 1.16vw;
    font-family: 'Mont-Bold';
    font-weight: 800;
    color: #58708E;

}

.cellphonesContainer {
    display: flex;
    width: auto;
    align-items: center;
    flex-direction: row;
    position: relative;
}

.cellphoneBox {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 2.36vw, 34px);
    width: clamp(1px, 20.34vw, 293px);
    height: clamp(1px, 36.94vw, 532px);
    border-radius: 2.08vw;
    box-shadow: 8px -1px 24px 0px rgba(0, 0, 0, 0.22);
    z-index: 0;
    right: 0;

    opacity: 1;
    transition: right ease-in-out 1s;
    position: absolute;
}

.cellphoneBoxRight {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 2.36vw, 34px);
    width: clamp(1px, 20.34vw, 293px);
    height: clamp(1px, 36.94vw, 532px);
    border-radius: 2.08vw;
    box-shadow: 8px -1px 24px 0px rgba(0, 0, 0, 0.22);
    z-index: 0;
    left: 0;

    opacity: 1;
    transition: left ease-in-out 1s;
    position: absolute;

}


.biosName {
    align-self: center;
    width: clamp(1px, 8.88vw, 127.93px);
    height: clamp(1px, 1,08vw, 15.67px);
}

.waveIcon{
    width: clamp(1px, 3.6vw, 53px);
    height: clamp(1px, 2.43vw, 35px);

}

.appleIcon {
    width: clamp(1px, 1.95vw, 28px);
    height: clamp(1px, 2.46vw, 35.5px);

}

.innerBoxWrapper {
    display: flex;
    flex-direction: column;
    margin-top: clamp(1px, 2.86vw, 41.32px);
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 0.9vw, 13px);

}

.innerTitleContainer {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 0.625vw, 9px);
    

}

/*font-weight 700*/

.innerTitle {
    color: var(--dark-blue);
    font-size: clamp(1px, 1.52vw, 22px);
    line-height: 100%;
}

.innerSubtitle {
    color: var(--dark-blue);
    font-size: clamp(1px, 0.83vw, 12px);
    line-height: clamp(1px, 1.2vw, 17px);
}

.innerBoxOuterWrapper {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 1.45vw, 20.88px);
}

.waveIcon {
    width: clamp(1px, 3.6vw, 53px);
    height: clamp(1px, 2.4vw, 35px);

}

.appleIcon {
    width: clamp(1px, 1.95vw, 28.11px);
    height: clamp(1px, 2.47vw, 35.59px);
}

.graph1 {
    width: clamp(1px, 17.15vw, 247px);
    height: clamp(1px, 8.06vw, 116.12px);

}

.verMasResultados {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 0,19vw, 2.84px);
    font-size: clamp(1px, 0.77vw, 11.18px);
    margin-top: clamp(1px, 1.87vw, 27.04px);
    line-height: 100%;
    color: #58708E; 
}

.verMasArrow {
    width: clamp(1px, 0.69vw, 10px);
    height: clamp(1px, 0.34vw, 5px);
}

.middleCellphone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 19.55vw, 282px);
    height: clamp(1px, 41.5vw, 599px);
    border-radius: clamp(1px, 1.8vw, 26px);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    gap: clamp(1px, 1.25vw, 25.22px);
    background-color: white;
    z-index: 1;
    
}

.biosLogoIcon {
    width: clamp(1px, 5.25vw, 75.65px);
    height: clamp(1px, 4.52vw, 65.2px);
}

.animate {
    background-color: red;

}

.leftBoxTransitionStyles {
    right: clamp(1px, 1.38vw, 16px);
}

/*right cellphone styles*/

.rightCellphoneInnerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fireIconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fireIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: clamp(1px, 0.99vw, 14.32px);
    font-size: clamp(1px, 0.45vw, 6.59px);
    gap: clamp(1px, 0.18vw, 2.7px);
}

.fruitBasketWrapper {
    margin-top: clamp(1px, 1.02vw, 14.43px);
    padding-right: clamp(1px, 2.91vw, 41.96px);
}

.fruitBasket {
    width: clamp(1px, 16.18vw, 233.03px);
    height: clamp(1px, 13.96vw, 201.045px);
}

.fruitStats {
    margin-top: clamp(1px, 1.95vw, 28.22px);
}

.fruitStatsImg {
    width: clamp(1px, 14.58vw, 209.963px);
    height: clamp(1px, 8.56vw, 123.361px);
    flex-shrink: 0;
}

.verMasResultadosRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 0,19vw, 2.84px);
    font-size: clamp(1px, 0.77vw, 11.18px);
    margin-top: clamp(1px, 10.87vw, 47.04px);
    line-height: 100%;
    color: #58708E;    
}

/*********************/

.sabeMasContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: clamp(1px, 4.44vw, 64px);
    gap: clamp(1px, 2.08vw, 30px);
}

.caloriasBottomText {
    font-size: clamp(1px, 0.83vw, 12.09px);
    font-weight: 700;
}

.fireText {
    font-size: clamp(1px, 0.45vw, 6.59px);
    font-weight: 400;
}

.recRowContainerMobile {
    display: none;
}

.descargarInformeText {
    font-size: clamp(1px, 1.11, 16px);
    color: var(--new-blue);
    font-weight: 700;
}

@media screen and (max-width: 478px) {

    .cellphoneOuterWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 11.9vw;
    }
    
    .cellphoneInnerWrapper {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 4.23vw, 61px);
        align-items: center;
    }
    
    
    .titleText {
        font-size: clamp(1px, 3.38vw, 14px);
        line-height: clamp(1px, 5.07vw, 21px);
        text-align: center;
        width: clamp(1px, 58.21vw, 241px);
    }
    
    .recsCol {
        display: none;
        flex-direction: column;
        gap: clamp(1px, 4.51vw, 65px);
        position: relative;
        margin-right: 0px;
        margin-left: 0px;
        transition: all ease-in-out 1s;
        
    
    }
    
    .recRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2.63vw;
        color: var(--new-blue);
        
    }
    
    .cellphonesContainer {
        display: flex;
        width: auto;
        align-items: center;
        flex-direction: row;
        position: relative;
        
    }
    
    .cellphoneBox {
        display: flex;
        flex-direction: column;
        padding-top: clamp(1px, 3.77vw, 15.61px);
        width: clamp(1px, 32.47vw, 134.45px);
        height: clamp(1px, 58.98vw, 244.18px);
        border-radius: 2.08vw;
        box-shadow: 8px -1px 24px 0px rgba(0, 0, 0, 0.22);
        z-index: 0;
        right: 0;
        opacity: 1;
        transition: right ease-in-out 1s;
        position: static;
        margin-right: -6vw;
    }
    
    .cellphoneBoxRight {
        display: flex;
        flex-direction: column;
        padding-top: clamp(1px, 2.36vw, 34px);
        width: clamp(1px, 32.48vw, 134.48px);
        height: clamp(1px, 58.98vw, 244.18px);
        border-radius: 2.08vw;
        box-shadow: 8px -1px 24px 0px rgba(0, 0, 0, 0.22);
        z-index: 0;
        left: 0;
        opacity: 1;
        transition: left ease-in-out 1s;
        position: static;
        margin-left: -6vw;
    
    }
    
    
    .biosName {
        align-self: center;
        width: clamp(1px, 12.97vw, 53.7px);
        height: clamp(1px, 1.37vw, 5.7px);
    }
    
    
    .appleIcon {
       
        width: clamp(1px, 5.06vw, 20.95px);
        height: clamp(1px, 6.48vw, 26.53px);
    
    }
    
    .innerBoxWrapper {
        display: flex;
        flex-direction: column;
        margin-top: clamp(1px, 2.86vw, 41.32px);
        align-items: center;
        justify-content: center;
        gap: clamp(1px, 0.9vw, 13px);
    
    }
    
    .innerTitleContainer {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 0.93vw, 3.89px);
        
    
    }
    
    /*font-weight 700*/
    
    .innerTitle {
        color: var(--dark-blue);
        font-size: clamp(1px, 2.43vw, 10.1px);
        line-height: 100%;
    }
    
    .innerSubtitle {
        color: var(--dark-blue);
        font-size: clamp(1px, 1.32vw, 5.5px);
        line-height: clamp(1px, 1.2vw, 17px);
    }
    
    .innerBoxOuterWrapper {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 1.45vw, 20.88px);
    }
    
    .waveIcon {
        width: clamp(1px, 9.5vw, 39.51px);
        height: clamp(1px, 6.28vw, 26px);
    
    }
    
   
    
    .graph1 {
        width: clamp(1px, 27.38vw, 113.37px);
        height: clamp(1px, 12.87vw, 53.29px);
    
    }
    
    .verMasResultados {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: clamp(1px, 0.26vw, 1.11px);
        font-size: clamp(1px, 1.23vw, 5.1px);
        margin-top: 8.99vw;
        line-height: 100%;
        color: #58708E; 
    }
    
    .verMasArrow {
        width: clamp(1px, 1.1vw, 4.59px);
        height: clamp(1px, 0.55vw, 2.29px);
    }
    
    .middleCellphone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: clamp(1px, 31.26vw, 129.43px);
        height: clamp(1px, 66.42vw, 274.98px);
        border-radius: clamp(1px, 1.8vw, 26px);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
        gap: clamp(1px, 2.79vw, 11.57px);
        background-color: white;
        z-index: 1;
        
    }
    
    .biosLogoIcon {
        width: clamp(1px, 8.38vw, 34.72px);
        height: clamp(1px, 7.23vw, 29.93px);
    }
    
    .animate {
        background-color: red;
    
    }
    
    .leftBoxTransitionStyles {
        right: clamp(1px, 1.38vw, 16px);
    }
    
    /*right cellphone styles*/
    
    .rightCellphoneInnerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .fireIconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .fireIconContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: clamp(1px, 0.99vw, 14.32px);
        font-size: clamp(1px, 0.45vw, 6.59px);
        gap: clamp(1px, 0.18vw, 2.7px);
    }
    
    .fruitBasketWrapper {
        margin-top: clamp(1px, 1.02vw, 14.43px);
        padding-right: clamp(1px, 2.91vw, 41.96px);
    }
    
    .fruitBasket {
        width: clamp(1px, 25.9vw, 107.242px);
        height: clamp(1px, 92.27vw, 92.27px);
    }
    
    .fruitStats {
        margin-top: clamp(1px, 1.95vw, 28.22px);
    }
    
    .fruitStatsImg {
        width: clamp(1px, 23.03vw, 95.35px);
        height: clamp(1px, 13.65vw, 56.55px);
        flex-shrink: 0;
    }
    
    .verMasResultadosRight {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: clamp(1px, 0,19vw, 2.84px);
        font-size: clamp(1px, 1.23vw, 5.1px);
        margin-top: 3.3vw;
        line-height: 100%;
        color: #58708E;    
    }
    
    /*********************/
    
    .sabeMasContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: clamp(1px, 10.14vw, 42px);
        gap: clamp(1px, 4.58vw, 19px);
    }

    .caloriasBottomText {
        font-size: clamp(1px, 1.34vw, 5.55px);
        font-weight: 700;
    }
    
    .fireText {
        font-size: clamp(1px, 0.72vw, 3px);
        font-weight: 400;
    }

    .recRowContainerMobile {
        display: flex;
        align-items: center;
        gap: 4.83vw;
        width: 100vw;
        overflow: auto;
        margin-top: clamp(1px, 11.76vw, 48.69px);   
        padding-left: 5vw;
        padding-right: 5vw;
        box-sizing: border-box;
        scrollbar-width: 0;
    }

    .recRowContainerMobile::-webkit-scrollbar {
        width: 0;
    }

    .descargarInformeText {
        font-size: clamp(1px, 3.38vw, 14px);
        color: var(--new-blue);
        font-weight: 700;
    }
}