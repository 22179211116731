.buttonBlue {
    background-color: #58708E;
    color: white;
    font-size: clamp(1px, 1.25vw, 14px);
    padding: clamp(1px, 0.97vw, 14px) clamp(1px, 2.84vw, 41px) clamp(1px, 0.97vw, 14px) clamp(1px, 2.84vw, 41px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 60px;
    font-weight: 400;
    line-height: clamp(1px, 1.59vw, 23px);
    transition: all ease 0.5s;
    
}

.buttonBlue:hover {
    background-color: #151A21;
}

@media screen and (max-width: 478px) {
    
    .buttonBlue {
        background-color: #58708E;
        color: white;
        font-size: clamp(1px, 2.89vw, 12px);
        padding: clamp(1px, 3.62vw, 15px) clamp(1px, 6.6vw, 27.4px) clamp(1px, 3.62vw, 15px) clamp(1px, 6.6vw, 27.4px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border-radius: 66.24px;
        font-weight: 400;
        line-height: clamp(1px, 3.59vw, 14.88px);
    }
    
}