:root {
    --dark-blue: #151A21;
}

.aboutMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: clamp(1px, 6.73vw, 97px);
    
}

.headerMessage {
    font-family: 'Urbanist';
    font-size: clamp(1px, 5.55vw, 80px);
    text-align: center;
    color: var(--dark-blue);
    line-height: 92%;
}

.headerSubtitle {
    font-size: clamp(1px, 3.12vw, 45px);
    width: clamp(1px, 64.09vw, 923px);
    color: var(--dark-blue);
    line-height: clamp(1px, 4.72vw, 68px);
    text-align: center;
    font-weight: 300;
    margin-top: clamp(1px, 4.72vw, 68px);
    margin-bottom: clamp(1px, 4.72vw, 48px);
}

@media screen and (max-width: 478px) {

    .aboutMessageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        padding-bottom: initial;
    }
    
    .headerMessage {
        font-family: 'Urbanist';
        font-size: max(8.21vw, 34px);
        text-align: center;
        color: var(--dark-blue);
        line-height: max(9.36vw, 38.76px);
    }
    
    .headerSubtitle {
        font-size: max(5.79vw, 24px);
        width: max(83.57vw, 356px);
        color: #58708E;
        line-height: max(7.56vw, 31.3px);
        text-align: center;
        font-weight: 300;
        margin-top: max(7.97vw, 33px);
        margin-bottom: max(7.97vw, 33px);
    }
    
    
}