

.arrowDownPosition {
    /*position: absolute;
    left: 50%;
    top: clamp(1px, 44.23vw, 831px);*/
    z-index: 999;
}

.arrowDownContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;
    width: clamp(1px, 1.52vw, 22px);
    height: clamp(1px, 2.91vw, 42px);
    border-radius: 10.5px;
    background: linear-gradient(to bottom, rgba(198, 255, 77, 0) 0%, rgba(198, 255, 77, 1) 100%);
    
}

.innerArrowSize {
    width: clamp(1px, 0.48vw, 7px);
    height: clamp(1px, 0.24vw, 3.5px);
}

.innerArrows {
    display: flex;
    flex-direction: column;
    transition: all ease-in-out 0.5s;
}

@media screen and (max-width: 478px) {

    

    .arrowDownPosition {
        /*position: absolute;
        left: 50%;
        top: clamp(1px, 44.23vw, 831px);*/
        z-index: 999;
    }

    .arrowDownContainer {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1px;
        width: clamp(1px, 1.52vw, 22px);
        height: clamp(1px, 2.91vw, 42px);
        border-radius: 10.5px;
        background: linear-gradient(to bottom, rgba(198, 255, 77, 0) 0%, rgba(198, 255, 77, 1) 100%);
        
    }

    .innerArrowSize {
        width: clamp(1px, 0.48vw, 7px);
        height: clamp(1px, 0.24vw, 3.5px);
    }

    .innerArrows {
        display: flex;
        flex-direction: column;
        transition: all ease-in-out 0.5s;
    }
        
}