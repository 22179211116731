:root {
    --dark-blue: #151A21;
    --new-blue: #58708E;
}

.footerOuterWrapper {
    position: relative;
    padding-top: clamp(1px, 10.9vw, 157px);
    padding-bottom: clamp(1px, 3.1vw, 44.7px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(1px, 86.38vw, 1244px);
    height: clamp(1px, 46.94vw, 676px);
    border-radius: clamp(1px, 4.16vw, 60px);
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(20px);
}

.dnacut {
    position: absolute;
    right: 0;
    bottom: 0;
    width: clamp(1px, 41.52vw, 598px);
    height: clamp(1px, 41.52vw, 598px);
}

.leafcut {
    position: absolute;
    left: 0;
    top: 50%;
    width: clamp(1px, 20.83vw, 300px);
    height: clamp(1px, 20.83vw, 350px);
}

.pinkcut {
    position: absolute;
    top: 0;
}

.biosLogoFooter {
    width: clamp(1px, 3.8vw, 54.77px);
    height: clamp(1px, 3.27vw, 47.22px);
    color: #151A21;

}


.volverArrow {
    position: absolute;
    right: clamp(1px, 2.15vw, 31px);
    top: clamp(1px, 2.5vw, 36px);
    width: clamp(1px, 8.47vw, 122px);
    height: clamp(1px, 8.47vw, 122px);
    transition: all ease 0.5s;
}

.volverArrow:hover {
    opacity: 0.5;
}

.biosLogoFooter {
    margin-top: clamp(1px, 7.15vw, 103px);
    align-self: center;
}

.footerLinkRow {
    display: flex;
    gap: clamp(1px, 2.56vw, 36.87px);
    margin-top: clamp(1px, 5.74vw, 82.78px);
    color: var(--dark-blue);
}

.innerFooterLink {
    font-size: clamp(1px, 1.38vw, 20px);
    line-height: clamp(1px, 1.88vw, 27.2px);
    transition: all ease 0.5s;
}

.innerFooterLink:hover {
    color: #8b8d90;
}



.footerHeaderContainer {
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    font-size: clamp(1px, 3.19vw, 46px);
    line-height: clamp(1px, 3.19vw, 46px);
    color: var(--dark-blue);
    opacity: 0.5;
    margin-top: clamp(1px, 4.51vw, 65px);
    
}

.footerBottomText {
    color: var(--dark-blue);
    font-family: 'Urbanist', sans-serif;
    font-size: clamp(1px, 0.9vw, 13.02px);
    line-height: clamp(1px, 2.29vw, 33.05px);
}

.volverArribaText {
    position: absolute;
    right: 0;
    margin-top: 4vw;
    margin-right: -5.61vw;
    color: var(--dark-blue);
    text-align: center;
    transform: rotate(-90deg);
    font-size: clamp(1px, 1.38vw, 20px);
    line-height: 108.5%;
}

.redesFooterContainer {
    color: #151A21;
}

.hideDesktop {
    display: none;
}


@media screen and (max-width: 478px) {

    .hide {
        display: none !important;
    }

    .hideDesktop {
        display: flex;
    }

    .volverArrowContainerMobile {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4vw;
        right: 4.66vw;
        top: clamp(1px, 9.17vw, 38px);
        width: clamp(1px, 8.25vw, 34.17px);

        
    }

    .arrowAndLogoCOntainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footerOuterWrapper {
        position: relative;
        padding-top: clamp(1px, 10.9vw, 157px);
        padding-bottom: clamp(1px, 3.1vw, 44.7px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .footerWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: clamp(1px, 83.57vw, 346px);
        height: clamp(1px, 108.69vw, 450px);
        border-radius: clamp(1px, 14.49vw, 60px);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding-top: clamp(1px, 19vw, 77px);
    }
    
    .dnacut {
        position: absolute;
        right: 0;
        bottom: 0;
        width: clamp(1px, 41.52vw, 598px);
        height: clamp(1px, 41.52vw, 598px);
    }
    
    .leafcut {
        position: absolute;
        left: 0;
        top: 50%;
        width: clamp(1px, 20.83vw, 300px);
        height: clamp(1px, 20.83vw, 300px);
    }
    
    .pinkcut {
        position: absolute;
        top: 0;
        width: max(37.14vw, 153.8px);
        height: max(37.14vw, 153.8px);
    }
    
    .biosLogoFooter {
        width: clamp(1px, 20.4vw, 84.47px);
        height: clamp(1px, 17.58vw, 72.82px);
        margin-top: 0;
        color: #151A21;
    
    }
        
    
    .volverArrow {
        position: static;
        right: 0;
        top: 0;
        width: clamp(1px, 8.23vw, 34.1px);
        height: clamp(1px, 8.23vw, 34.1px);
    }
    

    
    .footerLinkRow {
        display: flex;
        gap: clamp(1px, 2.41vw, 10px);
        margin-top: clamp(1px, 11.11vw, 46px);
        color: var(--dark-blue);
    }
    
    .innerFooterLink {
        
        font-size: clamp(1px, 2.89vw, 12px);
        line-height: clamp(1px, 1.88vw, 27.2px);
        text-decoration: underline;
        
    
    }
    
    .footerHeaderContainer {
        text-align: center;
        font-family: 'Urbanist', sans-serif;
        font-size: clamp(1px, 3.38vw, 14px);
        line-height: clamp(1px, 4vw, 16.7px);
        color: var(--dark-blue);
        opacity: 1;
        margin-top: clamp(1px, 11.11vw, 46px);
        
    }
    
    .footerBottomText {
        color: var(--dark-blue);
        font-family: 'Urbanist', sans-serif;
        font-size: clamp(1px, 2.89vw, 12px);
        line-height: clamp(1px, 3.59vw, 14.88px);
        text-align: center;
        margin-top: clamp(1px, 20.95vw, 86.74px);
    }
    
    .volverArribaText {
        position: static;
        right: 0;
        margin-top: 0;
        margin-right: 0;
        color: var(--new-blue);
        text-align: right;
        transform: rotate(-90deg);
        font-size: clamp(1px, 2.89vw, 12px);
        line-height: 100%;
        width: 30vw;
        margin-top: 10vw;
    }
    
    .redesFooterContainer {
        color: #151A21;
        margin-top: clamp(1px, 14.97vw, 62px) !important;
        gap: 5vw !important
    }

    .fbicon {
        width: clamp(1px, 2.41vw, 10px);
        height: clamp(1px, 3.24vw, 13.43px);

    }

    .instaicon {
        width: clamp(1px, 4.83vw, 20px);
        height: clamp(1px, 3.24vw, 13.43px);
    }

    .twittericon {
        width: clamp(1px, 4.34vw, 18px);
        height: clamp(1px, 3.24vw, 13.43px);
    }
    
}   