

.articleCoverContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 53.47vw;
    max-height: 770px;
   
    z-index: 0;
    /*background-image: url('../content/posts/media/1629045681971.jpg');*/
    
}

.coverBackgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.coverTitleContainer {
    display: flex;
    flex-direction: column;
    margin-top: clamp(1px, 12.98vw, 187px);
    margin-left: clamp(1px, 2.77vw, 40px);
    z-index: 1;

}

.goBackRowContainer {
    display: flex;
    flex-direction: row;
    gap: clamp(1px, 1.25vw, 18.13px);
    align-items: center;
}

.gobackArrow {
    width: clamp(1px, 4.16vw, 60px);
    height: auto;
    cursor: pointer;
    transition: all ease 0.3s;
    border-radius: 100%;
}



#recBack {
    border-radius: 100%;
    stroke: white;
    transition: all ease 0.5s;
}

#recBack:hover {
    background-color: white;
    stroke: #151A21;
}

.gobackText {
    font-family: 'Urbanist', 'Mont', sans-serif;
    font-size: clamp(1px, 1.73vw, 25px);
    line-height: 100%;
    font-weight: 400;
    color: white;
}

.coverTitle {
    margin-left: clamp(1px, 5.42vw, 78.13px);
    font-size: clamp(1px, 5.55vw, 80px);
    color: white;
    font-family: 'Urbanist', 'Mont', sans-serif;
    width: clamp(1px, 47.84vw, 689px);
    line-height: 92%;
}

.articleBodyContainer {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 4.93vw, 71px);
    height: auto;
    gap: clamp(1px, 6.25vw, 90px);
    overflow: hidden;
}

.articleBodyText {
    height: auto;
    width: clamp(1px, 43.88vw, 632px);
    font-size: clamp(1px, 1.66vw, 24px);
    padding-left: clamp(1px, 8.19vw, 118px);
}

.articleCenterImageContainer {
    align-self: flex-start;
    overflow: hidden;
    
}

.articleCenterImage {
    width: auto;
    height: auto;
    
}

.articleBottomRow {
    display: flex;
    align-self: flex-start;
    padding-left: clamp(1px, 8.19vw, 118px);
    gap: clamp(1px, 3.19vw, 46px);
}

.articleBottomText {
    font-size: clamp(1px, 1.66vw, 24px);
    width: clamp(1px, 43.88vw, 632px);
}

.hideDesktop {
    display: none;
}


@media screen and (max-width: 478px) {
    
.hideDesktop {
    display: block;
}

.hideMobile {
    display: none;
}

.articleCoverContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 135.74vw;
    max-height: 562px;
   
    z-index: 0;
    /*background-image: url('../content/posts/media/1629045681971.jpg');*/
    
}

.coverBackgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.coverTitleContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4vw;
    margin-left: clamp(1px, 8.21vw, 34px);
    z-index: 1;

}

.goBackRowContainer {
    display: flex;
    flex-direction: row;
    gap: clamp(1px, 1.25vw, 18.13px);
    align-items: center;
}

.gobackArrow {
    width: clamp(1px, 8.25vw, 34.17px);
    height: auto;
    color: white;
    cursor: pointer;
}

.gobackText {
    font-family: 'Urbanist', 'Mont', sans-serif;
    font-size: clamp(1px, 3.38vw, 14px);
    line-height: 100%;
    font-weight: 400;
    color: white;
}

.coverTitle {
    margin-top: clamp(1px, 5.97vw, 33px);
    margin-left: clamp(1px, 5.42vw, 78.13px);
    font-size: clamp(1px, 8.21vw, 34px);
    color: white;
    font-family: 'Urbanist', 'Mont', sans-serif;
    width: clamp(1px, 71.9vw, 298px);
    line-height: 114%;
}

.articleBodyContainer {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 13.04vw, 54px);
    height: auto;
    gap: clamp(1px, 13.04vw, 54px);
    overflow: hidden;
}

.articleBodyText {
    height: auto;
    width: clamp(1px, 82.36vw, 341px);
    font-size: clamp(1px, 3.38vw, 14px);
    padding-left: clamp(1px, 8.19vw, 118px);
    text-wrap: wrap;
}

.articleCenterImageContainer {
    align-self: flex-start;
    overflow: hidden;
    
}

.articleCenterImage {
    width: auto;
    height: 64.73vw;
    max-width: 90%;
    max-height: 573px;
    
}

.articleBottomRow {
    display: flex;
    align-self: flex-start;
    flex-direction: column !important;
    padding-left: clamp(1px, 8.19vw, 118px);
    gap: clamp(1px, 10.86vw, 45px);
    padding-bottom: clamp(1px, 15vw, 62px);
}

.articleBottomText {
    width: clamp(1px, 82.36vw, 341px);
    font-size: clamp(1px, 3.38vw, 14px);
}



}