:root {
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
}

.change {
    display: flex;
    padding: clamp(0px, 0.97vw, 14px) clamp(0px, 2.63vw, 38px);
    border-radius: 92px;
    border: 1px solid white;
    color: white;
    max-height: fit-content;
    max-width: fit-content;
    background-color: transparent;
    align-items: center;
    font-size: clamp(1px, 1.25vw, 18px);
    transition: all ease 0.5s;
}

.notchange {
    display: flex;
    padding: clamp(0px, 0.97vw, 14px) clamp(0px, 2.63vw, 38px);
    border-radius: 92px;
    border: 1px solid var(--Dark-Blue, #151A21);
    max-height: fit-content;
    max-width: fit-content;
    background-color: transparent;
    align-items: center;
    font-size: clamp(1px, 1.25vw, 18px);
    transition: all ease 0.5s;
}

.change:hover {
    background-color: #FFF;
    color: #151A21;
}

.notchange:hover {
    background-color: #151A21;
    color: #FFF;
}