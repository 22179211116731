

.recVerticalOuterWrapper {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 4.65vw, 67px);
    align-items: center;
    margin-top: clamp(1px, 23.5vw, 450px);
}

.recsVerticalContainer {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 2.15vw, 31px);
    width: 100%;
    align-items: center;
}

.recsRow {
    display: flex;
    gap: clamp(1px, 0.55vw, 8px);
    /*width: 65vw;
    width: clamp(1px, 73vw, 1300px);
    flex-wrap: wrap;*/
}



.rowGaps {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 2.15vw, 31px);
    align-items: center;
}

.rowContainersMobile {
    display: none;
}

.gapsBetweenRows {
    margin-bottom: clamp(1px, 2.15vw, 31px);
}



@media screen and (max-width: 478px) {
        
    .gapsBetweenRows {
        margin-bottom: clamp(1px, 9.92vw, 41.09px);
    }


    .recVerticalOuterWrapper {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 4.65vw, 67px);
        align-items: center;
        
    }

    .recsVerticalContainer {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 2.15vw, 31px);
    }

    .recsRow {
        display: flex;
        flex-wrap: wrap;
        width: 79vw;
        gap: clamp(1px, 0.96vw, 4px);
    }

 
    
    .rowContainersMobile {
        display: flex;
        flex-direction: column;
        gap: max(9.92vw, 41.09px);
    }

    .hide {
        display: none;
    }

}