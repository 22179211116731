.mobileMenuWrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    transition: all ease 0.5s;
    top: 0px;
    z-index: 9999999999;
    background-color: #93B0D8;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    
}

.menuLinksColumn {
    display: flex;
    flex-direction: column;
    gap: max(6.76vw, 28px);
    width: 100%;
    align-items: center;
    
}

.menuLinkRow {
    display: flex;
    justify-content: space-between;
    width: max(62.07vw, 257px);
    padding-bottom: max(3.62vw, 15px);
    border-bottom: 1px solid #FFF;
}

.menuLinkRowTitle {
    font-size: max(4.83vw, 20px);
    color: white;
    font-family: 'Mont';
    font-weight: 300;
    line-height: max(6.61vw, 27.4px);
    letter-spacing: -0.6px;
}

.redesLinks {
    display: flex;
    gap: 5vw;
    width: 100%;
    justify-content: center;
    color: white;
   
}

.fbicon {
    width: clamp(1px, 2.41vw, 10px);
    height: clamp(1px, 3.24vw, 13.43px);

}

.instaicon {
    width: clamp(1px, 4.83vw, 20px);
    height: clamp(1px, 3.24vw, 13.43px);
}

.twittericon {
    width: clamp(1px, 4.34vw, 18px);
    height: clamp(1px, 3.24vw, 13.43px);
}

.coreMenuColumnWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
    padding-bottom: max(20.28vw, 84px);
}

.redesAndMessageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: max(6.65vw, 27.57px);
    
}

.messageContainer {
    text-align: center;
    font-size: max(2.89vw, 12px);
    color: white;
    font-family: 'Mont';
    font-weight: 200;
}

.bubbleLogo {
    position: absolute;
    width: max(35.74vw, 148px);
    height: auto;
    /*top: max(127.77vw, 529px);*/
    top: 529px;
    right: max(4.1vw, 17px)
}

.almondsPos {
    position: absolute;
    width: max(24.87vw, 103px);
    height: auto;
    top: max(86.47vw, 358px);
    right: -10%;
    
}

.leafPos {
    position: absolute;
    width: max(31.48vw, 130.362px);
    height: auto;
    top: max(113.76vw, 471px);
    left: -10%;
}

.saladPos {
    position: absolute;
    width: max(22.14vw, 91.667px);
    height: auto;
    top: max(136.71vw, 566px);
    left: max(32.6vw, 135px);

}

.wakingUpPos {
    position: absolute;
    width: max(26.32vw, 109px);
    height: auto;
    top: max(101.44vw, 420px);
    right: max(30.19vw, 125px);
}

@media screen and (min-width: 479px) {  
    .mobileMenuWrapper {
        display: none;
    }
    
}

@media screen and (max-height: 667px) {

    .bubbleLogo {
        position: absolute;
        width: max(35.74vw, 148px);
        height: auto;
        /*top: max(127.77vw, 529px);*/
        top: 109vw;
        right: -10%;
    }

    .almondsPos {
        position: absolute;
        width: max(24.87vw, 103px);
        height: auto;
        top: 75vw;
        right: -20%;
        
    }
    
    .leafPos {
        position: absolute;
        width: max(31.48vw, 130.362px);
        height: auto;
        top: 104vw;
        left: -10%;
    }
    
    .saladPos {
        display: none;
        position: absolute;
        width: max(22.14vw, 91.667px);
        height: auto;
        top: max(136.71vw, 566px);
        left: max(32.6vw, 135px);
    
    }
    
    .wakingUpPos {
        position: absolute;
        width: max(26.32vw, 109px);
        height: auto;
        top: 95vw;
        right: max(30.19vw, 125px);
    }
}

@media screen and (min-height: 667px) and (max-height: 740px) {

    .saladPos {
        display: none;
    }

    .bubbleLogo {
        right: 0;
        top: 115vw;
    }

    .wakingUpPos {
        top: 95vw;
    }
    
}