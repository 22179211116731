.navContainer {
    height: auto;
    width: auto;
    padding: clamp(0px, 2.84vw, 41px) clamp(0px, 5.2vw, 75px) clamp(0%, 7.77vw, 112px) clamp(0px, 5.2vw, 75px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.logoContainer {
    width: auto;
    display: flex;
    align-items: center;
    gap: 1vw;
    max-width: fit-content;
    
}

.biosLogo {
    width: 4.02vw;
    max-width: 58px;
    height: 3.38vw;
    max-height: 48.78px;
    
}

.biosName {
    width: 10.06vw;
    max-width: 145px;
    height: 1.08vw;
    max-height: 15.59px;
}

.linksContainer {
    display: flex;
    flex-direction: row;
    gap: 2.43vw;
    /*margin-left: -14.3vw;*/
    /*font-size: 0.83vw;*/
    font-size: clamp(0px, 1.11vw, 16px);
    
}



.linkContainerChild {
    transition: all ease 0.5s;
}


.linkContainerChild:hover {
   
    color: #000;
    
    
}

.contactoContainer {
    display: flex;
    /*padding: 14px 2.63vw;*/
    padding: clamp(0px, 0.97vw, 14px) clamp(0px, 2.63vw, 38px);
    border-radius: 92px;
    border: 1px solid var(--Dark-Blue, #151A21);
    max-height: fit-content;
    max-width: fit-content;
    background-color: transparent;
    align-items: center;
    font-size: clamp(1px, 1.25vw, 18px);

}

.contactoText {
    height: auto;
    max-height: fit-content;
    
}

.rightColumnWrapper {
    display: flex;
    flex-direction: row;
    gap: 22.3vw;

}

.menuButtonContainer {
    display: none;
}


@media screen and (max-width: 478px) {

    .menuButtonContainer {
        display: block;
        width: max(12.41vw, 51px);
        height: max(4.58vw, 19px);
        z-index: 999999;
    }


    .navContainer {
        height: auto;
        width: auto;
        padding: clamp(0px, 19.8vw, 82px) clamp(0px, 6.15vw, 25.47px) clamp(0%, 13.81vw, 57.21px) clamp(0px, 6.15vw, 25.47px);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
    
    .logoContainer {
        width: auto;
        display: flex;
        align-items: center;
        gap: 1vw;
        max-width: fit-content;
        
    }
    
    .biosLogo {
        width: 9.39vw;
        max-width: 38.9px;
        height: 7.91vw;
        max-height: 32.78px;
        
    }
    
    .biosName {
        display: none;
        width: 10.06vw;
        max-width: 145px;
        height: 1.08vw;
        max-height: 15.59px;
    }
    
    .linksContainer {
        display: none;
        flex-direction: row;
        gap: 2.43vw;
        margin-left: -14.3vw;
        /*font-size: 0.83vw;*/
        font-size: clamp(0px, 1.11vw, 16px);
    
    }
    
    .contactoContainer {
        display: flex;
        /*padding: 14px 2.63vw;*/
        padding: clamp(0px, 0.97vw, 14px) clamp(0px, 2.63vw, 38px);
        border-radius: 92px;
        border: 1px solid var(--Dark-Blue, #151A21);
        max-height: fit-content;
        max-width: fit-content;
        background-color: transparent;
        align-items: center;
        font-size: clamp(1px, 1.25vw, 18px);
    
    }
    
    .contactoText {
        height: auto;
        max-height: fit-content;
        
    }
    
    .rightColumnWrapper {
        display: flex;
        flex-direction: row;
        gap: 22.3vw;
    
    }

    .hide {
        display: none;
    }
    
}