

li::marker {
    font-size: 20px;
}

.mainBackgroundWrapper {
    background-color: #93B0D8;
    width: 100%;
    height: auto;
}

.headerPlanesContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: clamp(1px, 6.73vw, 97px);
}

.headerPlanesText {
    text-align: center;
    font-size: clamp(1px, 5.55vw, 80px);
    color: white;
    line-height: clamp(1px, 5.11vw, 73.6px);
    font-weight: 300;
    letter-spacing: -2px;
}

.planOuterWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: clamp(1px, 7.63vw, 110px);
    background-color: #93B0D8;
    /*temp*/
    padding-bottom: 10vw;
}

.planColumn1 {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 3.05vw, 44px);
    padding-right: clamp(1px, 2.56vw, 37px);
    gap: clamp(1px, 3.68vw, 53px);
}

.planSelectionButton {
    /*padding-right: clamp(1px, 16.45vw, 237px);*/
    width: clamp(1px, 27.22vw, 392px);
    padding-bottom: clamp(1px, 2.5vw, 36px);
    border-bottom: 1px solid #FFF;
    font-size: clamp(1px, 2.08vw, 30px);
    color: white; 
    letter-spacing: clamp(1px, 0.83vw, 12px);
    line-height: clamp(1px, 4.46vw, 64.3px);
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    transition: all ease-out 0.5s;
}

.planSelectionButton:hover {
    color: #FFF !important;
    border-color: #FFF !important;
}

.planColumn2 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: clamp(1px, 2.77vw, 40px);
    border-left: 1px solid #FFF;
    transition: all ease-in-out 0.5s;
    
   
}

.planTextTitle {
    color: white;
    font-size: clamp(1px, 2.08vw, 30px);
    font-weight: 300;
    line-height: 100%;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    
}

.planBulletsItems {
    color: white;

}

.planTextDescription {
    font-size: clamp(1px, 2.08vw, 30px);
    width: clamp(1px, 50.76vw, 731px);
    color: white;
    font-weight: 300;

}

.planTextBottomRow {
    display: flex;
    align-items: center;
    margin-top: clamp(1px, 4.65vw, 67px);
    gap: clamp(1px, 2.29vw, 33px);

}

.downloadLink {
    color: white;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    cursor: pointer;
}

.planTextBullets {
   
}

.planBulletsItems {
    font-size: clamp(1px, 2.08vw, 30px);

}

.planAnual {
    position: absolute;
    display: flex;
    flex-direction: column;
    transition: all ease 0.5s;
    width: auto;
    height: auto;
}

.planSemestral {
    transition: all ease 1s;    
}

.hideDesktop {
    display: none;
}

@media screen and (max-width: 478px) {

    
    .hide {
        display: none;
    }

    .hideDesktop {
        display: block;
    }

    .mainBackgroundWrapper {
        background-color: #93B0D8;
        width: 100%;
        height: auto;
    }

    .headerPlanesContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
    }

    .headerPlanesText {
        text-align: center;
        font-size: max(8.21vw, 34px);
        color: white;
        line-height: max(9.36vw, 38.76px);
        font-weight: 300;
    }

    .planOuterWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: clamp(1px, 7.63vw, 110px);
        background-color: #93B0D8;
        /*temp*/
        padding-bottom: 10vw;
    }

    .planColumn1 {
        display: flex;
        flex-direction: column;
        padding-top: clamp(1px, 3.05vw, 44px);
        padding-right: clamp(1px, 2.56vw, 37px);
        gap: clamp(1px, 3.68vw, 53px);
    }

    .planSelectionButton {
        /*padding-right: clamp(1px, 16.45vw, 237px);*/
        width: clamp(1px, 27.22vw, 392px);
        padding-bottom: clamp(1px, 2.5vw, 36px);
        border-bottom: 1px solid #FFF;
        font-size: clamp(1px, 2.08vw, 30px);
        color: white; 
        letter-spacing: clamp(1px, 0.83vw, 12px);
        line-height: clamp(1px, 4.46vw, 64.3px);
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        transition: all ease-out 0.5s;
    }

    .planSelectionButton:hover {
        color: #FFF !important;
        border-color: #FFF !important;
    }

    .planColumn2 {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: clamp(1px, 2.77vw, 40px);
        border-left: 1px solid #FFF;
        transition: all ease-in-out 0.5s;
    
    
    }

    .planTextTitle {
        color: white;
        font-size: clamp(1px, 2.08vw, 30px);
        font-weight: 300;
        line-height: 100%;
        text-decoration-line: underline;
        text-decoration-thickness: 2px;
        
    }

    .planBulletsItems {
        color: white;

    }

    .planTextDescription {
        font-size: clamp(1px, 2.08vw, 30px);
        width: clamp(1px, 50.76vw, 731px);
        color: white;
        font-weight: 300;

    }

    .planTextBottomRow {
        display: flex;
        align-items: center;
        margin-top: clamp(1px, 4.65vw, 67px);
        gap: clamp(1px, 2.29vw, 33px);

    }

    .downloadLink {
        color: white;
        text-decoration-line: underline;
        text-decoration-thickness: 2px;
        cursor: pointer;
    }

    .planTextBullets {
    
    }

    .planBulletsItems {
        font-size: clamp(1px, 2.08vw, 30px);

    }

    .planAnual {
        position: absolute;
        display: flex;
        flex-direction: column;
        transition: all ease 0.5s;
        width: auto;
        height: auto;
    }

    .planSemestral {
        transition: all ease 1s;    
    }
    
}