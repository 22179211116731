@font-face {
    font-family: 'Mont';
    src: url('./assets/fonts/Mont-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('./assets/fonts/Mont-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

/* MontFamily */
@font-face {
    font-family: 'Mont-Thin';
    src: url('./assets/fonts/MontFamily/Mont-Thin.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mont-Light';
    src: url('./assets/fonts//MontFamily/Mont-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mont-Regular';
    src: url('./assets//fonts/MontsFile/Mont-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont-Bold';
    src: url('./assets/fonts/MontFamily/Mont-Bold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mont-Book';
    src: url('./assets/fonts/MontsNap/Mont-Book.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont-SemiBold';
    src: url('./assets/fonts/MontFamily/Mont-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}


.montBook {
    font-family: 'Mont-Book';
}

a {
    text-decoration: none;
    color: inherit;
}

.mont400 {
    font-family: 'Mont';
    color: #323A46;
}

.redesFooterContainer {
    display: flex;
    gap: clamp(1px, 2.77vw, 40px);
    margin-top: clamp(1px, 11.25vw, 162px);
}

.montThin {
    font-family: 'Mont-Thin';
}

.montLight {
    font-family: 'Mont-Light';
}

.montRegular {
    font-family: 'Mont-Regular';
}

.montBold {
    font-family: 'Mont-Bold';
}

.montSemiBold {
    font-family: 'Mont-SemiBold';
}