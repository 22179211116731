

.hMessageContainer {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 6.73vw, 97px);
    gap: 5vw;
}

.headerMessageC {
    font-family: 'Urbanist';
    font-size: clamp(1px, 5.55vw, 80px);
    text-align: center;
    color: var(--dark-blue);
    line-height: 92%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 3.68vw, 53px);
}

.formInput {
    padding-bottom: clamp(1px, 1.11vw, 16px) !important;
    border-bottom: 1px solid var(--dark-blue);
    width: clamp(1px, 44.3vw, 638px);
    border-style: none;
    border-bottom: 1px solid var(--dark-blue);
    font-size: clamp(1px, 1.52vw, 22px);
    line-height: 136%;
    text-transform: capitalize;
    outline: none;
    color: var(--dark-blue);
    resize: none;
    appearance: none;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.buttonBlue {
    align-self: flex-end;
 
}


@media screen and (max-width: 478px) {

    

    .hMessageContainer {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        gap: max(13vw, 54px);
    }

    .headerMessageC {
        font-family: 'Urbanist';
        font-size: max(8.21vw, 34px);
        text-align: center;
        color: var(--dark-blue);
        line-height: max(9.36vw, 38.76px);
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        gap: max(12.31vw, 51px);
    }

    .formInput {
        padding-bottom: max(3.14vw, 13px) !important;
        border-bottom: 1px solid var(--dark-blue);
        width: max(83.57vw, 346px);
        border-style: none;
        border-bottom: 1px solid var(--dark-blue);
        font-size: max(3.38vw, 14px);
        line-height: max(5vw, 21px);
        text-transform: capitalize;
        outline: none;
        color: var(--dark-blue);
        resize: none;
        appearance: none;
    }

    .buttonBlue {
        align-self: center;
        font-size: max(2.89vw, 12px);
       
    }

    button, input[type="submit"], input[type="reset"] {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
    
}