:root {
    --new-blue: #58708E;
    --light-blue: #BCD8FF;
    --num-gen-bg: #F5F5F5;
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
}

.membresiaWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 37.28%);*/
    background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(255,255,255,0) 100%);
    
}

.cornerLogoContainer {
    place-self: flex-end;
    margin-top: clamp(1px, 3.95vw, 57px);
    margin-right: clamp(1px, 3.75vw, 54px);
}

.titleContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -1.25vw;
    text-align: center;
}

.titleTextMembresia {
    font-size: clamp(1px, 2.22vw, 32px);
    line-height: clamp(1px, 3.12vw, 45px);
    letter-spacing: -0.06vw;
    color: #8A8A8A;
    font-weight: 600;
}

.planesWrapper {
    display: flex;
}

.planContainerActive {
    display: flex;
    position: relative;
    /*background-image: url('../../assets/imgs/membresia/aroExt.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-clip: unset;
    background-position: 0%;*/
    padding: clamp(1px, 2.77vw, 40px);
    width: clamp(1px, 50vw, 720px);
    height: clamp(1px, 50vw, 720px);
    align-items: center;
    justify-content: center;

    transition: all ease-in-out 0.5s;
}

.bgImageContainerMem {
    position: absolute;
    padding: clamp(1px, 2.77vw, 40px);
    width: clamp(1px, 50vw, 720px);
    height: clamp(1px, 50vw, 720px);
    transition: all ease-in 0.5s;
}

.rightContainerBubble {
    margin-left: -360px;
    
}

.innerPlanContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(1px, 38.11vw, 548.88px);
    height: clamp(1px, 38.11vw, 548.88px);
    border-radius: 470.471px;
    border: 3px solid #FFF;
    background: linear-gradient(56deg, rgba(66, 102, 150, 0.50) 32.04%, rgba(194, 220, 255, 0.50) 96.24%);
    backdrop-filter: blur(7.841176509857178px);
    
      
}

.innerTitleMem {
    margin-top: clamp(1px, 5vw, 72px);
    width: clamp(1px, 8.88vw, 127.9px);
    height: clamp(1px, 0.95vw, 13.7px);
}

.titleDivWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*???*/
    gap: 1vw;
    margin-top: 2vw;
}

.bigTextTitle {
    font-size: clamp(1px, 4.79vw, 69px);
    line-height: clamp(1px, 4.37vw, 63px);
    text-align: center;
    color: #FFF;
    letter-spacing: -5.175px;
}

.bottomLine {
    width: clamp(1px, 30.31vw, 125.5px);
    height: 1px;
    background-color: #FFF;
}

.lowerTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*???*/
    margin-top: 1vw;
    /**/
    gap: clamp(1px, 1.94vw, 28px) ;
}

.lowerText {
    text-align: center;
    font-size: clamp(1px, 1.66vw, 24px);
    line-height: clamp(1px, 2.15vw, 31px);
    color: #FFF;
    font-family: 'Urbanist';
    font-weight: 500;
}

.sliderMemWrapper {
    display: none;
}


@media screen and (max-width: 478px) {

    
    .membresiaWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*background-color: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 37.28%);*/
        background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(255,255,255,0) 100%);
        
    }
    
    .cornerLogoContainer {
        place-self: flex-end;
        margin-top: clamp(1px, 3.95vw, 57px);
        margin-right: clamp(1px, 3.75vw, 54px);
    }
    
    .titleContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        /*margin-top: -1.25vw;*/
        margin-top: clamp(1px, 8.69vw, 36px);
        text-align: center;
    }
    
    .titleTextMembresia {
        font-size: clamp(1px, 3.38vw, 14px);
        line-height: clamp(1px, 5.07vw, 21px);
        letter-spacing: -0.06vw;
    }
    
    .planesWrapper {
        display: none;
    }
    
    .planContainerActive {
        display: flex;
        position: relative;
        /*background-image: url('../../assets/imgs/membresia/aroExt.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-clip: unset;
        background-position: 0%;*/
        padding: clamp(1px, 2.77vw, 40px);
        width: clamp(1px, 63.52vw, 263px);
        height: clamp(1px, 63.52vw, 263px);
        align-items: center;
        justify-content: center;
    
        transition: all ease-in-out 0.5s;
    }
    
    /*edit svg img size*/
    .bgImageContainerMem {
        position: absolute;
        padding: clamp(1px, 2.77vw, 40px);
        width: clamp(1px, 59.42vw, 246px); /*edited*/
        height: clamp(1px, 83.33vw, 345px);
        transition: all ease-in 0.5s;
        overflow: hidden;
    }
    
    .rightContainerBubble {
        margin-left: -360px;
        
    }
    
    .innerPlanContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: clamp(1px, 63.52vw, 263px);
        height: clamp(1px, 63.52vw, 263px);
        border-radius: 100%;
        border: 3px solid #FFF;
        background: linear-gradient(56deg, rgba(66, 102, 150, 0.50) 32.04%, rgba(194, 220, 255, 0.50) 96.24%);
        backdrop-filter: blur(7.841176509857178px);
        
          
    }
    
    .innerTitleMem {
        display: flex;
        margin-top: clamp(1px, 4.34vw, 18px);
        width: clamp(1px, 14.8vw, 61.28px);
        height: clamp(1px, 1.59vw, 6.59px);
    }
    
    .titleDivWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*???*/
        gap: 1vw;
        margin-top: 2.89vw;
    }
    
    .bigTextTitle {
        font-size: clamp(1px, 8.21vw, 34px);
        line-height: clamp(1px, 9.36vw, 38.76px);
        text-align: center;
        color: #FFF;
        letter-spacing: -1.02px;
        font-weight: 300;
    }
    
    .bottomLine {
        width: clamp(1px, 19.43vw, 279.8px);
        height: 1px;
        background-color: #FFF;
    }
    
    .lowerTextWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*???*/
        margin-top: 1vw;
        /**/
        gap: clamp(1px, 1.94vw, 28px) ;
    }
    
    .lowerText {
        text-align: center;
        font-size: clamp(1px, 3.38vw, 14px);
        line-height: clamp(1px, 5.07vw, 21px);
        color: #FFF;
        font-weight: 300;
    }

    /*membresia slider*/

    .mobileMembresiaSliderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
       width: 70vw;
       
    }

    .sliderMemWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrowSliderContainer{
        width: clamp(1px, 8.25vw, 34.17px);
        height:  clamp(1px, 8.25vw, 34.17px);
        transition: all ease 0.5s;
    }
    
    
    
    
}