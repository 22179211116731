:root {
    --dark-blue: #151A21;
}

.aboutNavContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: clamp(1px, 5.2vw, 75px);
    padding-right: clamp(1px, 5.2vw, 75px);
    padding-top: clamp(1px, 2.84vw, 41px);
}

.biosAboutLogo {

}

.biosLogoAbout {
    width: 4.02vw;
    max-width: 58px;
    height: 3.38vw;
    max-height: 48.78px;
}

.mainButtonsContainer {
    display: flex;
    align-items: center;
    /*gap: clamp(1px, 2.43vw, 35px);*/
    gap: 2.43vw;
    padding-left: 146.88px;
    /*padding-left: 4.9vw;*/
}



@media screen and (max-width: 478px) {

    .aboutNavContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
 padding: clamp(0px, 19.8vw, 82px) clamp(0px, 6.15vw, 25.47px) clamp(0%, 13.81vw, 57.21px) clamp(0px, 6.15vw, 25.47px);
    }
    
    .biosAboutLogo {
    
    }
    
    .biosLogoAbout {
        width: 9.39vw;
        max-width: 38.9px;
        height: 7.91vw;
        max-height: 32.78px;
    }
    
    .mainButtonsContainer {
        display: none;
        align-items: center;
        gap: clamp(1px, 2.43vw, 35px);
    }

    .hide {
        display: none;
    }

}