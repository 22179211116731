

.pilaresOuterWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 2.98vw, 43.04px);
    padding-top: clamp(1px, 14.16vw, 204px);
    padding-bottom: clamp(1px, 9.99vw, 143.89px);
    
}

.columnContainer1 {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 2.37vw, 34.19px);
    width: clamp(1px, 57.08vw, 821.96px);
}

.columnContainer2 {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 13.76vw, 57px);
}

.greenLogoContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 12.7vw, 183px);
    height: clamp(1px, 12.7vw, 183px);
    margin-right: -2vw;
}

.rowContainer1 {
    display: flex;
    align-items: center;
    align-self: flex-end;
    
}

.rowContainer2 {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 2.29vw, 33.11px);
}

.subrowContainer1 {
    display: flex;
    flex-direction: row;
    gap: clamp(1px, 0.59vw, 8.52px);
    margin-left: clamp(1px, 10.97vw, 158px);
}

.subrowColumn1 {
    display: flex;
    flex-direction: column;
    height: inherit;
    align-items: flex-end;
    justify-content: flex-end;

}

.subrowColumn2 {
    display: flex;
    flex-direction: column;
    gap: 0.33vw;
}

.subRowColumn2Title {
    font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #A1B3CA;
}

.subRowColumn2Sub {
    font-family: 'Poppins';
    font-weight: 700;
    color: #A1B3CA;
    font-size: clamp(1px, 2.04vw, 29.41px);
    line-height: 100%;
}

.yogurt {
    width: clamp(1px, 53.19vw, 766px);
    height: clamp(1px, 21.66vw, 312px);
    
}

.flower {
    width: clamp(1px, 17.7vw, 255px);
    height: clamp(1px, 17.7vw, 255px);
}

.greenLogo {
    width: clamp(1px, 6.45vw, 93px);
    height: clamp(1px, 6.73vw, 97px);
}

.blogo {
    width: clamp(1px, 11.82vw, 170.31px);
    height: clamp(1px, 1.27vw, 18.31px);
}

.pilaresText {
    width: clamp(1px, 35.41vw, 510px);
    height: auto;
    font-size: clamp(1px, 2.22vw, 32px);
    line-height: clamp(1px, 2.88vw, 41.51px);
    color: #8A8A8A;

}

.bLogoMobile {
    display: none;
}

.fireLogoStyles {
    width: clamp(1px, 1.37vw, 19.85px);
    height: clamp(1px, 1.86vw, 26.86px);
}

@media screen and (max-width: 478px) {

    

.pilaresOuterWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1px, 2.98vw, 43.04px);
    padding-top: clamp(1px, 35.39vw, 146.55px);
    padding-bottom: clamp(1px, 22.46vw, 93px);
    
}

.columnContainer1 {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 0.25vw, 1.07px);
    /*width: clamp(1px, 57.08vw, 821.96px);*/
    width: 100%;
}

.columnContainer2 {
    display: flex;
    flex-direction: column;

}

.greenLogoContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 22.21vw, 91.97px);
    height: clamp(1px, 22.21vw, 91.97px);
    margin-right: -2vw;
    display: none;
}

.greenLogoContainerMobile {
    width: clamp(1px, 22.21vw, 91.97px);
    height: clamp(1px, 22.21vw, 91.97px);
    border-radius: 100%;
    border: 1px solid #EEE;
    margin-right: -4vw;
    z-index: 2;
}

.greenLogoContainer :nth-child(2) {
    display: none;
}



.rowContainer1 {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    justify-content: flex-end;
    
}

.bLogoMobile {
    display: block;
    margin-right: 11.7vw;
}

.rowContainer2 {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 2.29vw, 33.11px);
    width: 100%;
    align-items: center;
}

.subrowContainer1 {
    display: flex;
    flex-direction: row;
    gap: clamp(1px, 0.59vw, 8.52px);
    margin-left: clamp(1px, 8.21vw, 34px);
    align-self: flex-start;
}

.subrowColumn1 {
    display: flex;
    flex-direction: column;
    height: inherit;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
}

.subrowColumn2 {
    display: flex;
    flex-direction: column;
    gap: 0.33vw;
}

.subRowColumn2Title {
    font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #A1B3CA;
    font-size: clamp(1px, 3.38vw, 14px);
    font-weight: 300;
}

.subRowColumn2Sub {
    font-family: 'Poppins';
    font-weight: 700;
    color: #A1B3CA;
    font-size: clamp(1px, 4.34vw, 18px);
    line-height: 100%;
    font-weight: 600;
}

.yogurt {
    width: clamp(1px, 99.9vw, 413.63px);
    height: clamp(1px, 38.8vw, 160.65px);
    
}

.flower {
    width: clamp(1px, 30.96vw, 128.2px);
    height: clamp(1px, 30.96vw, 128.2px);
}

.greenLogo {
    width: clamp(1px, 6.45vw, 93px);
    height: clamp(1px, 6.73vw, 97px);
}

.blogo {
    display: none;
    width: clamp(1px, 11.82vw, 170.31px);
    height: clamp(1px, 1.27vw, 18.31px);
}

.pilaresText {
    width: clamp(1px, 86.95vw, 360px);
    height: auto;
    font-size: clamp(1px, 5.79vw, 24px);
    line-height: clamp(1px, 7.53vw, 31.2px);
    color: #8A8A8A;
    text-align: center;
    letter-spacing: -0.72px;
    color: #58708E;
    font-weight: 300;
}

.fireLogoStyles {
    width: clamp(1px, 2.41vw, 9.98px);
    height: clamp(1px, 3.26vw, 13.5px);
    margin-right: 2.42vw;
}

.pilaresSaberMasMobile {
    display: none;
}
    
}