

.centering-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.max-width-container {
    width: auto;
    max-width: 1440px;

}

.order-selection-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.order-selection-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.order-option-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10%;
    padding-bottom: 10%;
    width: clamp(150px, 16vw, 360px);
    height: 400px;
    border-radius: 30px;
    border: 2px solid black;
    transition: all ease 0.2s;
    cursor: pointer;
    overflow: hidden;
}

.recomended-label {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 10%;
    border-bottom-right-radius: 30px;
    background-color: #58708E;
    color: white;
    font-size: 15px;
}

.order-text {
    font-size: min(1.15vw, 16px);
    line-height: 120%;
}

@media screen and (min-width: 478px) and (max-width: 1650px) {
    .recomended-label {
        padding: initial;
        padding-top: 10%;
        padding-bottom: 10%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }

 

    .order-option-container {
        width: 300px;
        height: 400px;
    }
    
}
 
@media screen and (max-width: 800px) {
    .order-option-container {
        height: 300px;
        border: 0px;
    }

    .recomended-label {
        width: 100%;
        border-bottom-right-radius: 0px;
    }


}

/*Checkout.js*/

.checkout-wrapper {
    display: flex;
    gap: 60px;
}

.left-col-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
}

.checkout-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.checkout-input, .PhoneInputInput {
    outline: none;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-family: 'Mont';
    border: 0.5px solid lightgrey;
    border-radius: 5px;
}

.right-col-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: min(20vw, 300px);
    /*border-bottom: 1px solid lightgrey;*/
}

.summary-title {
    padding-bottom: 15px;
    border-bottom: 1px solid lightgrey;
    width: 100%;
}

@media screen and (max-width: 478px) {

    .checkout-wrapper {
        flex-direction: column-reverse;
        padding: 10%;
        align-items: center;
        gap: 35px;
        padding-top: 10px;
    }

    .right-col-container {
        width: 100%;
    }

    .PhoneInput {
        width: 100%;
    }

    .max-width-container {
        width: 100%;
    }

    .pay-block-container {
        width: auto !important;
    }

    .recomended-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: initial;
        padding-top: 10%;
        padding-bottom: 10%;
    }
}

/*OneTimePay.js*/

.pay-block-container {
    display: flex;
    flex-direction: column;
    width: clamp(100px, 30vw, 420px);
    
}

.button-container {
    transition: all ease 0.5s;
}

/*FinalizePay.js*/

.resumen {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subtitle {
    display: inline-block;
    width: 100%;
}

/*OrderDetail.js*/

.order-detail-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.order-summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.client-info-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}