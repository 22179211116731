

.loadingContainer {
    display: flex;
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 999;
    transition: all ease 0.1s;
}