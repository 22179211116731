:root {
    --new-blue: #58708E;
    --light-blue: #BCD8FF;
    --num-gen-bg: #F5F5F5;
    --out-quint: cubic-bezier(0.22, 1, 0.36, 1);
}

.backgroundCircle {
    width: 97%;
    height: clamp(1px, 90.97vw, 1310px);
    border-radius: 1400px;
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.10) inset;
    position: absolute;
    margin-top: -30px;
    left: 20px;
    z-index: -1;
}

.servicioOuterWrapper {
    display: flex;
    flex-direction: column;
    padding-top: clamp(1px, 17vw, 245px);
    align-items: center;
    justify-content: center;
    
}

.servicioTitle {
    color: var(--new-blue);
    font-size: clamp(1px, 4.16vw, 60px);
}

.servicioContainer {
    display: flex;
    gap: 5.55vw;
    margin-top: clamp(1px, 3.81vw, 55px);
}

.servicioLeftCol {
    display: flex;
    flex-direction: column;
    margin-top: clamp(1px, 3.81vw, 55px);
    /*background-image: url('../../assets/imgs/imgs-servicio/bgArc.svg');*/
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: padding-box;
    width: clamp(1px, 35.97vw, 518px);
    height: clamp(1px, 35.97vw, 556px);
    position: relative;
    justify-content: space-between;

    transition: all ease-out 0.5s;

}

.innerWheelWrapper {
    display: flex;
    flex-direction: column;
    width: clamp(1px, 35.97vw, 518px);
    height: clamp(1px, 35.97vw, 556px);
    justify-content: space-between;
    transition: all ease-out 0.5s;
    font-size: clamp(1px, 2.04vw, 29.4px);
    color: #A8A8A8;
    font-weight: 800;
}

.selectedNumber {
    font-size: clamp(1px, 2.63vw, 38px);
    color: #58708E;
    transition: all ease 0.5s;
}

.unSelectedNumber {
    font-size: clamp(1px, 2.04vw, 29.4px);
    color: #A8A8A8;
}

/*width: 98px;
height: 98px;
flex-shrink: 0;*/

.servicioRow1 {
    display: flex;
    flex-direction: row;   
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -40px;
    
}

.numberCircle1 {
    display: flex;
    width: clamp(1px, 6.8vw, 98px);
    height: clamp(1px, 6.8vw, 98px);
    align-items: center;
    justify-content: center;
    background-color: var(--light-blue);
    border-radius: clamp(1px, 6.8vw, 98px);
    transition: all ease-out 0.5s;

}

.numberCircle2 {
    display: flex;
    width: clamp(1px, 6.8vw, 98px);
    height: clamp(1px, 6.8vw, 98px);
    align-items: center;
    justify-content: center;
    background-color: var(--light-blue);
    border-radius: clamp(1px, 6.8vw, 98px);
    transition: all ease-out 0.5s;
    margin-right: -45px;
}

/*270*/
.svgWrapper {
    position: absolute;
    opacity: 1;
    transition: all ease-in-out 1s;
    width: clamp(1px, 18.75vw, 270px);
    height: auto;
    left: 50%;   
}

.numberRow2 {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
}



.numberCircleGeneric {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 5.27vw, 76px);
    height: clamp(1px, 5.27vw, 76px);
    border-radius: clamp(1px, 5.27vw, 76px);
    background-color: var(--num-gen-bg);
    transition: all ease-out 0.5s;
}

.numberCircleGeneric2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 5.27vw, 76px);
    height: clamp(1px, 5.27vw, 76px);
    border-radius: clamp(1px, 5.27vw, 76px);
    background-color: var(--num-gen-bg);
    transition: all ease-out 0.5s;
    margin-right: -40px;
}

.genericCircleLeft {
    margin-left: -40px;
}



.genericCircleRight {
    margin-right: -40px;
    z-index: 1;
}

.genericCircleBottom {
    margin-top: 0px;
    
}

.numberRow3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*padding-top: clamp(1px, 12.5vw, 180px);*/
    
}

.bgArcWrapper {
    position: absolute;
    width: clamp(1px, 36.25vw, 518px);
    height: clamp(1px, 36.25vw, 518px);
    z-index: -1;
    transition: all var(--out-quint) 1s;
    
}

.bgArcWrapperTransition {
    position: absolute;
    width: clamp(1px, 36.25vw, 518px);
    height: clamp(1px, 36.25vw, 518px);
    z-index: -1;
    /*transition: all var(--out-quint) 1.2s;*/
}

.centerImg {
    position: absolute;
    right: 115px;
    top: 105px;
    width: clamp(1px, 20.27vw, 292px);
    height: clamp(1px, 20.27vw, 292px);
    transition: all ease 0.5s;
}

/*Right Col*/

.servicioRightCol {
    margin-top: clamp(1px, 3.4vw, 49px);
    display: flex;
    flex-direction: column;
    
    /*gap: clamp(1px, 1.11vw, 16px);*/
    gap: 0;
    /*gap: clamp(1px, 1.7vw, 26px);*/
}

.rowWrapper {
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 1.11vw, 16px);
    width: clamp(1px, 40.76vw, 587px);
    color: var(--new-blue);
    height: 75px;
    overflow: hidden;
    transition: all ease-in-out 0.5s;
    padding-bottom: 0;
    border-bottom: 1px solid #DCDCDC;
    margin-top: 22px;
}

.rowWrapper.clicked {
    height: 300px;
}

.rowTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.rowTitleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: clamp(1px, 0.625, 9px);
    /*align-self: flex-end;*/
    
}

.accordeonButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(1px, 3.54vw, 51px);
    height: clamp(1px, 3.54vw, 51px);
    border-radius: clamp(1px, 5.06vw, 73px);
    border: 1px solid;
    border-color: var(--new-blue);
    transition: all var(--out-quint) 1s;
    cursor: pointer;
   
}

.accordeonButton:hover {
    opacity: 0.5;
}


.numPaso {
    
    font-size: clamp(1px, 1.38vw, 20px);
    opacity: 0;
    transition: all ease 1s;
}

.rowTitle {
    font-size: clamp(1px, 1.66vw, 24px);
    transition: all ease 0.7s;
    font-weight: 700;

}

.rowTextContainer {
    padding-left: clamp(1px, 1.94vw, 28px);
    width: clamp(1px, 31.11vw, 448px);
    text-wrap: wrap;
    font-size: clamp(1px, 1.66vw, 24px);
    transition: all ease 1s;
}

.bgArcWrapperChild {
    transition: all ease-in-out 10s;
    position: absolute;
    width: clamp(1px, 36.25vw, 518px);
    height: clamp(1px, 36.25vw, 518px);
}

.linkUnderMobile {
    display: none;
}


@media screen and (max-width: 478px) {

    /*
    border-radius: 276px;
background: var(--Blanco, #FFF);
box-shadow: 0.98571px 0px 0.98571px 0px rgba(0, 0, 0, 0.10) inset;
width: 276px;
height: 276px;
flex-shrink: 0;
*/
    .backgroundCircle {
        display: none;
    }

    .backgroundCircleMobile {
        display: static;
        width: clamp(1px, 66.66vw, 276px);
        height: clamp(1px, 66.66vw, 276px);
        border-radius: 100%;
        box-shadow: 0.98571px 0px 0.98571px 0px rgba(0, 0, 0, 0.10) inset;
        position: absolute;
        margin-top: 0;
        left: 0px;
        z-index: -1;
    }

    .bgContainerMobile {
        position: relative;
        width: clamp(1px, 66.66vw, 276px);
        height: clamp(1px, 66.66vw, 276px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .servicioOuterWrapper {
        display: flex;
        flex-direction: column;
        padding-top: clamp(1px, 17vw, 245px);
        align-items: center;
        justify-content: center;
        
    }
    
    .servicioTitle {
        color: var(--new-blue);
        font-size: clamp(1px, 4.16vw, 60px);
    }
    
    .servicioContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3.14vw;
        margin-top: initial;
    }
    
    .servicioLeftCol {
        display: flex;
        flex-direction: column;
        margin-top: clamp(1px, 3.81vw, 55px);
        /*background-image: url('../../assets/imgs/imgs-servicio/bgArc.svg');*/
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: padding-box;
        width: clamp(1px, 47.84vw, 198.07px);
        height: clamp(1px, 47.9vw, 198.34px);
        position: relative;
        justify-content: space-between;
    
        transition: all ease-out 0.5s;
    
    }
    
    .innerWheelWrapper {
        display: flex;
        flex-direction: column;
        width: clamp(1px, 47.84vw, 198.07px);
        height: clamp(1px, 47.9vw, 198.34px);
        justify-content: space-between;
        transition: all ease-out 0.5s;
    }
    
    /*width: 98px;
    height: 98px;
    flex-shrink: 0;*/
    
    .servicioRow1 {
        display: flex;
        flex-direction: row;   
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: -1.9vw;
        
    }
    
    .numberCircle1 {
        display: flex;
        width: clamp(1px, 6.8vw, 98px);
        height: clamp(1px, 6.8vw, 98px);
        align-items: center;
        justify-content: center;
        background-color: var(--light-blue);
        border-radius: clamp(1px, 6.8vw, 98px);
        transition: all ease-out 0.5s;
    
    }
    
    .numberCircle2 {
        display: flex;
        width: clamp(1px, 6.8vw, 98px);
        height: clamp(1px, 6.8vw, 98px);
        align-items: center;
        justify-content: center;
        background-color: var(--light-blue);
        border-radius: clamp(1px, 6.8vw, 98px);
        transition: all ease-out 0.5s;
        margin-right: -2.2vw;
    }
    
    /*270*/
    .svgWrapper {
        position: absolute;
        opacity: 1;
        transition: all ease-in-out 1s;
        width: clamp(1px, 18.75vw, 270px);
        height: auto;
        left: 50%;   
    }
    
    .numberRow2 {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
    }
    
    
    
    .numberCircleGeneric {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(1px, 5.27vw, 76px);
        height: clamp(1px, 5.27vw, 76px);
        border-radius: clamp(1px, 5.27vw, 76px);
        background-color: var(--num-gen-bg);
        transition: all ease-out 0.5s;
    }
    
    .numberCircleGeneric2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(1px, 5.27vw, 76px);
        height: clamp(1px, 5.27vw, 76px);
        border-radius: clamp(1px, 5.27vw, 76px);
        background-color: var(--num-gen-bg);
        transition: all ease-out 0.5s;
        margin-right: -40px;
    }
    
    .genericCircleLeft {
        margin-left: -1.9vw;
    }
    
    
    
    .genericCircleRight {
        margin-right: -1.9vw;
        z-index: 1;
    }
    
    .genericCircleBottom {
        margin-top: 0px;
        
    }
    
    .numberRow3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*padding-top: clamp(1px, 12.5vw, 180px);*/
        
    }
    
    .bgArcWrapper {
        position: absolute;
        width: clamp(1px, 47.84vw, 198.07px);
        height: clamp(1px, 47.9vw, 198.34px);
        z-index: -1;
        /*transition: all var(--out-quint) 1.2s;*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bgArcWrapperTransition {
        position: absolute;
        width: clamp(1px, 47.84vw, 198.07px);
        height: clamp(1px, 47.9vw, 198.34px);
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        /*transition: all var(--out-quint) 1.2s;*/
    }

    .bgArcWrapperChild {
        width: clamp(1px, 47.84vw, 198.07px);
        height: clamp(1px, 47.9vw, 198.34px);
        transition: all var(--out-quint) 0.2s;
    }
    
    .centerImg {
        position: absolute;
        top: 29px;
        right: 29px;

        /*
        width: clamp(1px, 20.27vw, 292px);
        height: clamp(1px, 20.27vw, 292px);
        */
        width: max(33.72vw, 139.632px);
        height:  max(33.72vw, 139.632px);
    }
    
    /*Right Col*/
    
    .servicioRightCol {
        display: flex;
        flex-direction: column;
        gap: 6vw;
        
        margin-top: clamp(1px, 3.4vw, 49px);
        
    }
    
    .rowWrapper {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 1.11vw, 16px);
        width: clamp(1px, 83.57vw, 346px);
        color: var(--new-blue);
        height: 43px;
        overflow: hidden;
        transition: all ease-in-out 0.5s;
        padding-bottom: 1.51vw;
        border-bottom: 1px solid #DCDCDC;
        
    }
    
    .rowWrapper.clicked {
        height: 300px;
    }
    
    .rowTitleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
    }
    
    .rowTitleContainer {
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 0.625, 9px);
        /*align-self: flex-end;*/
        
    }
    
    .accordeonButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(1px, 7.19vw, 29.8px);
        height: clamp(1px, 7.19vw,29.8px);
        border-radius: clamp(1px, 5.06vw, 73px);
        border: 1px solid;
        border-color: var(--new-blue);
    }
    
    .numPaso {
        font-size: clamp(1px, 3.38vw, 14px);
        display: none;
        opacity: 0;
    }
    
    .rowTitle {
        font-size: clamp(1px, 4.34vw, 18px);
    
    }
    
    .rowTextContainer {
        padding-left: 4.58vw;
        width: clamp(1px, 68.84vw, 285px);
        line-height: 150%;
        text-wrap: wrap;
        font-size: clamp(1px, 3.38vw, 14px);
        transition: all ease 1s;
    }

    .num3Mobile {
        margin-bottom: -1.9vw;
    }

    .linkUnderMobile {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8.93vw;
    }
    
}

@media screen and (min-width: 479px) and (max-width: 1500px) {
  
    .bgArcWrapperChild {
        transition: all 0s;
        width: clamp(1px, 36.25vw, 518px);
        height: clamp(1px, 34vw, 518px);
    }

    .centerImg {
        top: 5.9vw;
    }

    .numberRow3 {
        top: 0.69vw;
    }

    .numberRow2 {
        width: 95%;
        padding-left: 0.69vw;
        
    }
    .servicioRow1 {
        padding-top: 0.34vw;
    }

}

@media screen and (min-width: 479px) and (max-width: 1345px) {

    .centerImg {
        right: 8.55vw;
        top: 6.69vw;
    }
}

@media screen and (min-width: 479px) and (max-width: 1011px) {
    .servicioRow1 {
        padding-top: 1vw;
    }

    .numberRow2 {
        width: 92%;
        padding-left: 10px;
        padding-right: 10px;
    }

}

@media screen and (min-width: 479px) and (max-width: 762px) {
    .servicioRow1 {
        padding-top: 2vw;
    }

    .numberRow2 {
        width: 85%;
        padding-left: 2.5vw;
    }
}