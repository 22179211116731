:root {
    --gris-oscuro: #8A8A8A;
}


.cuidaOuterWrapper {
    display: flex;
    position: relative;
    height: 45.55vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.cuidaLeftCol {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: clamp(1px, 2.54vw, 36.6px);
    margin-left: clamp(1px, 4.16vw, 60px);
    z-index: 1;
}

.lowerFilter {
    position: absolute;
    width: 100%;
    height: clamp(1px, 19.58vw, 282px);
    z-index: 1;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    filter: blur(0px);
    margin-top: 25vw;
    transform: rotate(180deg);
}

.biosCuida {
    width: clamp(1px, 11.45vw, 165px);
    height: clamp(1px, 1.22vw, 17.7px);
}

.cuidaText {
    color: var(--gris-oscuro);
    font-size: clamp(1px, 2.22vw, 32px);
    line-height: clamp(1px, 3.12vw, 45px);
    width: clamp(1px, 28.81vw, 440px);
}

.imgcuida {
    
    width: clamp(1px, 74.44vw, 1072px);
    height: clamp(1px, 45.55vw, 656px);
    z-index: 0;
    overflow: hidden;
}


@media screen and (max-width: 478px) {

    
    
    .cuidaOuterWrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        height: auto;
        justify-content: space-between;
        align-items: center;
        margin-top: clamp(1px, 17.63vw, 73px);
    }
    
    .cuidaLeftCol {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: clamp(1px, 8.93vw, 37px);
        margin-left: 0;
        z-index: 1;
    }
    
    .lowerFilter {
        position: absolute;
        width: 100%;
        height: clamp(1px, 19.58vw, 282px);
        z-index: 1;
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        filter: blur(0px);
        margin-top: 25vw;
        transform: rotate(180deg);
    }
    
    .biosCuida {
        width: clamp(1px, 26.32vw, 109px);
        height: clamp(1px, 2.83vw, 11.72px);
       
    }
    
    .cuidaText {
        color: var(--gris-oscuro);
        font-size: clamp(1px, 5.79vw, 24px);
        line-height: clamp(1px, 7.53vw, 31.2px);
        color: #58708E;
        width: auto;
        text-align: center;
    }
    
    .imgcuida {
        
        /*width: clamp(1px, 74.44vw, 1072px);*/
        width: 100%;
        height: clamp(1px, 79.33vw, 328.45px);
        z-index: 0;
    }
    
    
    
    
    
    
}

