.carouselWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-top: 10vw;
    padding-bottom: 10vw;
    padding-left: clamp(1px, 4.16vw, 60px);
    gap: clamp(1px, 4.65vw, 67px);
}

.recomendacionesTituloExterior {
    color: #A1B3CA;
    font-size: clamp(1px, 3.125vw, 45px);
    line-height: clamp(1px, 3.125vw, 45px);
    align-self: flex-start;

}

@media screen and (max-width: 478px) {
    
    .carouselWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 10vw;
        padding-bottom: 10vw;
        padding-left: clamp(1px, 4.16vw, 60px);
        gap: clamp(1px, 8.21vw, 34px);
    }
    
    .recomendacionesTituloExterior {
        color: #58708E;
        font-size: clamp(1px, 5.79vw, 24px);
        line-height: clamp(1px, 7.53vw, 31.2px);
        align-self: center;
        text-align: center;
        font-weight: 300;
    
    }

    .hide {
        display: none;
    }
    
}